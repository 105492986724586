window.addEventListener('load', function() {
	var $window = $(window);
	var $body = $(document.body);
	// $('.mask-tel').mask("(99) 99999-9999");

	/**
	 * Setup do token de validação no cabeçalho
	 */
	$.ajaxSetup({
		headers: {
			'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		}
	});
});

/**
 * Objeto público com  métodos usados ao longo do app
 */

var speedometer = false;
var biAlready = false;
var miAlready = false;
var percentAlready2 = false;
var lastScrollTopH = 0;
var lastScrollTopA = 0;
var currentElemActive = 0;

var APP = {
	init: function () {
		console.log("JS init");
		var _this = this;
        _this.binds();
        _this.initSliders();
	},

	binds: function(){
		$("#hamburguer-btn, #hamburguer-btn-mobile").on("click", function(e){
			$("html, body").toggleClass("menu-mobile-active");

			if ($("html, body").hasClass("menu-mobile-active")) {
				$("#wrapper-menu-mobile").css("display", "block");
			} else {
				setTimeout(function() {
					$("#wrapper-menu-mobile").css("display", "none");
				}, 200);
			}
		});

		$(".scroll-to-target").on("click", function(){
			var target = $(this).data("target");

			$("html, body").animate({
				scrollTop: $($("#"+target)).offset().top
			}, 600);
		});

		$(".tab-item").on("click", function(){
			APP.changeTabContent($(this).data('tab'));
		});
		
		$(".btn-toggle-mobile").on("click", function(){
			APP.openMobileMenu();
		});

		$("#filters-trigger").on("click", function(){
			APP.openFiltersMenu();
		});
		
		$("#search-trigger").on("click", function(event){
			event.stopPropagation();
			APP.openSearchMenu();
		});
		
		$(".btn-toggle-map").on("click", function(event){
			var map = $(this).data("map");

			$("#"+map).toggleClass('show-map');
		});
		
		$("#calendar-trigger, #calendar").on("click", function(event){
			event.stopPropagation();
			APP.toggleCalendar(true);
		});
		
		$(".change-current-year").on("click", function(){
			APP.changeCurrentYear($(this).data("action"));
		});

		$("#search-trigger-mobile").on("click", function(event){
			event.stopPropagation();
			APP.openSearchMenuMobile();
		});

		$("#search-close-mobile").on("click", function(event){
			event.stopPropagation();
			APP.openSearchMenuMobile();
		});

		$("#input-video").on("change", function(event){
			$("#form-message p").text("Vídeo selecionado: " + event.target.value.toString());
		});

		$(".search-menu").on("click", function(event){
			event.stopPropagation();
			APP.openSearchMenu(false, true);
		});

		$(window).click(function(){
      APP.openSearchMenu(true);

			if ($("#veiculos-calendar")) {
				APP.toggleCalendar(false, true);
			}
		});

		$(window).on("scroll", function(){
      // TODO code here...
		});
	},

	initSliders: function () {
		if ($("#slider-home")) {
			$('#slider-home').slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: false,
				fade: true,
				dots: true,
				infinite: false,
			});
		}
		
		if ($("#best-seller-slider")) {
			$('#best-seller-slider').slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				autoplay: true,
				arrows: true,
				fade: true,
				dots: false,
				infinite: true,
				responsive: [
					{
						breakpoint: 761,
						settings: {
							arrows: false,
							infinite: true,
						}
					},
				]
			});
		}

		if ($("#depositions-slider-primeiro")) {
			var depoimentosSlick = $('#depositions-slider-primeiro').slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: true,
				fade: true,
				dots: true,
				infinite: false,
				autoplay: true,
				pauseOnFocus: false,
				pauseOnHover: false,
				rows: 0,
				prevArrow: $(".custom-arrows .slick-prev"),
				nextArrow: $(".custom-arrows .slick-next"),
			});
			
			$(".custom-arrows .slick-pause").on("click", function() {
				depoimentosSlick.slick('slickSetOption', 'autoplay', false);
				depoimentosSlick.slick('slickPause');
				
				$(".custom-arrows .slick-pause").css('display', 'none');
				$(".custom-arrows .slick-play").css('display', 'block');
			});
			
			$(".custom-arrows .slick-play").on("click", function() {
				depoimentosSlick.slick('slickSetOption', 'autoplay', true);
				depoimentosSlick.slick('slickPlay');
				
				$(".custom-arrows .slick-pause").css('display', 'block');
				$(".custom-arrows .slick-play").css('display', 'none');
			});

			$('#depositions-slider-primeiro').attr('style', 'overflow: visible !important');
			$('#depositions-slider-primeiro').attr('style', 'max-height: fit-content !important');
		}

		if ($("#videos-slider")) {
			$('#videos-slider').slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: true,
				fade: true,
				dots: true,
				infinite: false,
			});
		}
		
		if ($("#categories-slider")) {
			$('#categories-slider').slick({
				slidesToShow: 3,
				slidesToScroll: 1,
				arrows: true,
				dots: true,
				infinite: false,
				responsive: [
					{
						breakpoint: 1200,
						settings: {
							slidesToShow: 3,
						}
					},
					{
						breakpoint: 992,
						settings: {
							slidesToShow: 2,
						}
					},
					{
						breakpoint: 768,
						settings: {
							slidesToShow: 2,
							arrows: false,
						}
					},
					{
						breakpoint: 576,
						settings: {
							slidesToShow: 1,
							arrows: false,
						}
					},
				]
			});
			$('#categories-slider').css('display', 'block')
		}

		if ($("#testmonials-slider")) {
			$('#testmonials-slider').slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: true,
				dots: false,
				infinite: false,
			});
			$('#testmonials-slider').attr('style', 'overflow: visible !important');
			$('#testmonials-slider').attr('style', 'max-height: fit-content !important');
		}
		
		if ($("#marcas-slider")) {
			$('#marcas-slider').slick({
				slidesToShow: 5,
				slidesToScroll: 1,
				arrows: true,
				dots: false,
				infinite: true,
				responsive: [
					{
						breakpoint: 992,
						settings: {
							slidesToShow: 4,
						}
					},
					{
						breakpoint: 768,
						settings: {
							slidesToShow: 3,
						}
					},
					{
						breakpoint: 576,
						settings: {
							arrows: false,
							slidesToShow: 2,
						}
					},
				]
			});
			$('#marcas-slider').css('display', 'block')
			$('#marcas-slider').css('flex-direction', 'unset')
		}
		
		if ($("#veiculos-destaques-slider")) {
			$('#veiculos-destaques-slider').slick({
				slidesToShow: 3,
				slidesToScroll: 1,
				arrows: false,
				dots: false,
				infinite: false,
				responsive: [
					{
						breakpoint: 768,
						settings: {
							slidesToShow: 1,
						}
					},
				]
			});
			$('#veiculos-destaques-slider').css('display', 'block')
			$('#veiculos-destaques-slider').css('flex-direction', 'unset')
			$('#veiculos-destaques-slider').css('align-items', 'unset')
		}

		if ($("#veiculos-por-ano-slider")) {
			$('#veiculos-por-ano-slider').slick({
				slidesToShow: 3,
				slidesToScroll: 1,
				arrows: false,
				dots: false,
				infinite: false,
				responsive: [
					{
						breakpoint: 768,
						settings: {
							slidesToShow: 1,
						}
					},
				]
			});
			$('#veiculos-por-ano-slider').css('display', 'block')
		}

		if ($("#veiculo-gallery")) {
			$('#veiculo-gallery').slick({
				lazyLoad: 'ondemand',
				slidesToShow: 5,
				slidesToScroll: 1,
				arrows: true,
				dots: true,
				infinite: false,
				variableWidth: true,
				responsive: [
					{
						breakpoint: 800,
						settings: {
							infinite: true,
							dots: false
						}
					},
				]
			});
		}
		
		if ($("#veiculos-categoria")) {
			$('#veiculos-categoria').slick({
				slidesToShow: 3,
				slidesToScroll: 1,
				arrows: true,
				dots: false,
				infinite: false,
				responsive: [
					{
						breakpoint: 992,
						settings: {
							slidesToShow: 2,
						}
					},
					{
						breakpoint: 576,
						settings: {
							slidesToShow: 1,
						}
					},
				]
			});
		}
	},

	changeTabContent: function(id) {
		var tabCurrent = $('.tab-item.active').attr('id');

		$('.tab-item').removeClass('active');
		$('.tab-content').removeClass('active');

		if (tabCurrent !== ('tab-i'+id)) {
			$('#tab-i'+id).addClass('active');
			$('#tab-c'+id).addClass('active');
		}
	},

	openMobileMenu: function(){
		if(!$('body').hasClass('sub-menu-aberto-body')){
			$('body, html').toggleClass('menu-mobile-open');

			if( $('body, html').hasClass('menu-mobile-open') ){
				$('.btn-toggle-mobile').addClass('menu-collapsed');
				$("#hamburger-btn, #hamburger-btn-fixed").removeClass("to-arrow");
				$("#hamburger-btn, #hamburger-btn-fixed").addClass("from-arrow");
				$('html').css('overflow-y', 'hidden');
			} else {
				$('.btn-toggle-mobile').removeClass('menu-collapsed');
				$("#hamburger-btn, #hamburger-btn-fixed").addClass("to-arrow");
				$("#hamburger-btn, #hamburger-btn-fixed").removeClass("from-arrow");
				$('html').css('overflow-y', 'inherit');
			}
		}
	},

	openSearchMenu: function(forceClose, forceOpen){
		if (forceOpen) {
			$('.search-wrapper').addClass('search-open');
			return;
		}

		if (forceClose) {
			$('.search-wrapper').removeClass('search-open');
			return;
		}

		$('.search-wrapper').toggleClass('search-open');
	},

	openSearchMenuMobile: function(){
		$('body, html').toggleClass('busca-mobile-open');

		if( $('body, html').hasClass('busca-mobile-open') ){
			$('html').css('overflow-y', 'hidden');
			$('.search-wrapper').addClass('search-open');
		} else {
			$('html').css('overflow-y', 'inherit');
			$('.search-wrapper').removeClass('search-open');
		}
	},

	toggleCalendar: function(open, close){
		if (open) {
			$('#veiculos-calendar').addClass('calendar-open');
			$('#calendar').addClass('calendar-open');
			return;
		}

		if (close) {
			$('#veiculos-calendar').addClass('calendar-open');
			$('#calendar').addClass('calendar-open');
		}

		if($('#veiculos-calendar').hasClass('calendar-open')){
			$('#veiculos-calendar').removeClass('calendar-open');
			$('#calendar').removeClass('calendar-open');
		} else {
			$('#veiculos-calendar').addClass('calendar-open');
			$('#calendar').addClass('calendar-open');
		}
	},

	changeCurrentYear: function(action) {
		// console.log(action);
	},

	openFiltersMenu: function(){
		$('#veiculos-filters').toggleClass('filters-open');
		$('#filters-wrapper').toggleClass('filters-open');
		$('#veiculos-filters .btn-menu').toggleClass('active');

		if ($('#veiculos-filters').hasClass('filters-open')) {
			$('#resultados-estoque').removeClass('offset-lg-1');
			$('#resultados-estoque').addClass('offset-lg-2');
			
			$('#resultados-filtros').removeClass('col-lg-10');
			$('#resultados-filtros').addClass('col-lg-11');
		} else {
			$('#resultados-estoque').addClass('offset-lg-1');
			$('#resultados-estoque').removeClass('offset-lg-2');

			$('#resultados-filtros').addClass('col-lg-10');
			$('#resultados-filtros').removeClass('col-lg-11');
		}
	},

	isOnScreen: function(element, anchor) {
		if (element.offset()) {
			var elemPos = element.offset().top;
			var elemHeight = element[0].offsetHeight;

			var elemTop = (elemPos + elemHeight) - $(window).scrollTop() - 100;

			if (anchor === "top") {
				var elemTop = elemPos - $(window).scrollTop() + 400;
			} else if (anchor === "bottom") {
				var elemTop = elemPos - $(window).scrollTop();
			}

			var screenHeight = $(window).height();
			var isOnTop = (elemTop > screenHeight) ? false : true;

			if (isOnTop) {
				var isOnTop = ((elemPos + elemHeight) > $(window).scrollTop());
			}

			return isOnTop;
		}
	},
};
//expondo projeto padrao
window.addEventListener('load', function() {
	APP.init();
});
